<section class="mb-5 mt-4 mt-md-0">
  <h5 class="text-primary mb-3">Répartition hommes / femmes des répondants</h5>
  <div class="d-flex flex-column flex-md-row justify-content-evenly align-items-center mb-3">
    <p >Pourcentage d'hommes</p>
    <progressbar [max]="100" [value]="!loading ? stats.malePercent : 0"></progressbar><span class="ms-2" *ngIf="!loading">{{stats.malePercent}}%</span><span class="ms-2 opacity-25" *ngIf="loading">X%</span>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-evenly align-items-center mb-3">
    <p>Pourcentage de femmes</p>
    <progressbar [max]="100" [value]="!loading ? stats.femalePercent : 0"></progressbar><span class="ms-2" *ngIf="!loading">{{stats.femalePercent}}%</span><span class="ms-2 opacity-25" *ngIf="loading">X%</span>
  </div>
</section>

<section class="mb-5">
  <h5 class="text-primary mb-4">Nombre de personnes qui ont répondu au questionnaire Opale en indiquant faire partie des effectifs salariés</h5>
  <div class="d-flex flex-column flex-md-row justify-content-start align-items-center align-content-center mb-3">
    <span class="w-100 w-md-50 w-lg-40 text-center text-md-start">Depuis le début de la collaboration</span>
    <span class="shadow py-2 px-3 rounded ms-3 text-muted opacity-50 fw-bolder" [ngClass]="{'opacity-25' : loading, 'opacity-50': !loading}">{{!loading ? stats.count.total : 0}}</span>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-start align-items-center align-content-center mb-3">
    <span class="w-100 w-md-50 w-lg-40 text-center text-md-start">Depuis cette année :</span>
    <span class="shadow py-2 px-3 rounded ms-3 text-muted opacity-50 fw-bolder" [ngClass]="{'opacity-25' : loading, 'opacity-50': !loading}">{{!loading ? stats.count.last6months : 0}}</span>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-start align-items-center align-content-center mb-3">
    <span class="w-100 w-md-50 w-lg-40 text-center text-md-start">Depuis les 6 derniers mois :</span>
    <span class="shadow py-2 px-3 rounded ms-3 text-muted opacity-50 fw-bolder" [ngClass]="{'opacity-25' : loading, 'opacity-50': !loading}">{{!loading ? stats.count.year : 0}}</span>
  </div>
</section>

<section class="mb-5">
  <h5 class="text-primary mb-3">Les expositions aux types de violences parmi les salariés qui ont répondu au questionnaire Opale</h5>
  <table class="w-100">
    <thead>
      <tr>
        <th></th>
        <th>Début</th>
        <th>6 mois</th>
        <th>Année</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading" class="opacity-50">
        <td class="py-4">-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <ng-container *ngIf="!loading">
        <tr *ngFor="let group of stats.groups">
          <td class="py-4">{{group.name}}</td>
          <td>{{group.total}}</td>
          <td>{{group.last6months}}</td>
          <td>{{group.year}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</section>
