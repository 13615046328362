import {Component, Input, OnInit, Renderer2, ElementRef, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ActionService} from "../../../services/action/action.service";
import {NgFor, NgIf} from "@angular/common";
import {Subject, Subscription} from "rxjs";
import {AlertService} from "../../../services/alert/alert.service";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ModalReferentComponent} from "../../modals/modal-referent/modal-referent.component";

@Component({
  selector: 'app-dashboard-configs',
  standalone: true,
  imports: [
    NgFor,
    NgIf
  ],
  templateUrl: './dashboard-configs.component.html',
  styleUrl: './dashboard-configs.component.scss'
})
export class DashboardConfigsComponent implements OnInit, OnDestroy {

  @Input() type = 'EMPLOYEE';
  @Input() displayTypeEvent: Subject<any> = new Subject<any>();

  private subscription: Subscription;

  modalRef!: BsModalRef;

  actions: Array<any> = [];
  loading: boolean = false;

  constructor(
    private actionSrv: ActionService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private alertSrv: AlertService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.initActions();
    this.subscription = this.displayTypeEvent.subscribe((type: string) => {
      this.type = type;
      this.initActions();
    });
  }

  initActions() {
    const actionObservable = this.type === 'EMPLOYEE' ? this.actionSrv.employee() : this.actionSrv.customer();

    actionObservable.subscribe(
      (data) => {
        this.actions = data;
        setTimeout(() => {
          this.addClickEventListeners();
        }, 500);
      },
      (error) => {
        console.error('Data recovery error', error);
      }
    );
  }

  changeIsImplemented(action: any, isImplemented: boolean) {
    this.actions.find((item) => item.uniqid === action.uniqid).isImplemented = isImplemented;
  }

  transformContent(action: any): string {
    const displayValue = action.variable || 'XX';
    return action.content.replace(/\[\]/g, `<span class="badge bg-primary fs-6 pointer non-select action-var ${action.uniqid}">${displayValue}</span>`);
  }

  addClickEventListeners() {
    const clickableElements = this.elRef.nativeElement.querySelectorAll('span.action-var');
    clickableElements.forEach((element: HTMLElement) => {
      this.renderer.listen(element, 'click', () => {
        const classList = element.classList;
        const lastClass = classList.length > 0 ? classList[classList.length - 1] : null;
        if (lastClass) {
          this.handleClick(lastClass, element);
        } else {
          console.warn(`Element not found : ${element}`);
        }
      });
    });
  }

  handleClick(uniqid: string, spanElement: HTMLElement) {
    const action = this.actions.find(item => item.uniqid === uniqid);
    if (action) {
      this.editVariable(action, spanElement);
    }
  }

  editVariable(action: any, spanElement: HTMLElement) {
    const input = this.renderer.createElement('input');
    this.renderer.setAttribute(input, 'type', 'text');
    this.renderer.setProperty(input, 'value', action.variable || '');
    this.renderer.addClass(input, 'inline-edit-input');

    spanElement.textContent = '';
    this.renderer.appendChild(spanElement, input);
    input.focus();

    const completeEdit = () => {
      const newValue = input.value;
      if (newValue !== action.variable) {
        action.variable = newValue;
        spanElement.textContent = newValue;
        this.cdr.detectChanges();
        setTimeout(() => {
          this.addClickEventListeners();
        });
      } else {
        spanElement.textContent = action.variable;
      }
      this.renderer.removeChild(spanElement, input);
    };

    this.renderer.listen(input, 'blur', completeEdit);
    this.renderer.listen(input, 'keydown', (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        completeEdit();
      } else if (event.key === 'Escape') {
        this.renderer.removeChild(spanElement, input);
        spanElement.textContent = action.variable;
      }
    });
  }

  update() {
    this.loading = true;
    const params = {
      actions: this.actions
    }
    this.actionSrv.update(params).subscribe(
      (data) => {
        this.alertSrv.success('Actions enregistrées avec succès!')
      },
      (error) => {
        console.error('Data saving error', error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  editReferent(action: any) {
    const modalOptions: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        action: action
      }
    };
    this.modalRef = this.modalService.show(ModalReferentComponent, modalOptions);

    this.modalRef.onHide?.subscribe(() => {
      const updatedReferent = this.modalRef?.content.referent;
      if (updatedReferent) {
        action.referent = updatedReferent.referent;
        action.email = updatedReferent.email;
        action.phone = updatedReferent.phone;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
