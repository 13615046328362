import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ToastrModule} from "ngx-toastr";
import {ModalModule} from "ngx-bootstrap/modal";
import {NavbarComponent} from "../components/navbar/navbar.component";
import {FooterComponent} from "../components/footer/footer.component";
import {DashboardHomeComponent} from "../components/dashboard/dashboard-home/dashboard-home.component";
import {DashboardConfigsComponent} from "../components/dashboard/dashboard-configs/dashboard-configs.component";
import {DashboardParametersComponent} from "../components/dashboard/dashboard-parameters/dashboard-parameters.component";
import {DashboardStatsComponent} from "../components/dashboard/dashboard-stats/dashboard-stats.component";
import {ImagePipe} from "../pipes/image.pipe";
import {ModalReferentComponent} from "../components/modals/modal-referent/modal-referent.component";
import {BlogComponent} from "../components/blog/blog.component";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    NavbarComponent,
    FooterComponent,
    DashboardHomeComponent,
    DashboardConfigsComponent,
    DashboardParametersComponent,
    DashboardStatsComponent,
    ModalReferentComponent,
    BlogComponent,
    ImagePipe
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    DashboardHomeComponent,
    DashboardConfigsComponent,
    DashboardParametersComponent,
    DashboardStatsComponent,
    ModalReferentComponent,
    BlogComponent,
    ImagePipe
  ]
})
export class SharedModule { }
