import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToolsService} from "../tools/tools.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private http: HttpClient,
    private toolsSrv: ToolsService
  ) { }

  articles(): Observable<any> {
    return this.http.get(`${this.toolsSrv.getApiEndpoint()}blog/articles`);
  }
}
