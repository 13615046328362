<h2 class="text-primary-light mb-3 mt-5 mt-md-0">Bienvenue sur votre plateforme partenaire</h2>
<div class="row justify-content-center mb-5 d-none d-md-flex">
  <div class="col-6 col-md-4">
    <div class="shadow rounded d-flex flex-column justify-content-center align-items-center m-3 pt-3 pb-1 pointer" (click)="changeDisplay('PARAMETERS')">
      <span class="material-icons fs-1 text-info mb-3">settings</span>
      <p class="fs-4 text-primary-light fw-bolder">Paramètres</p>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div class="shadow rounded d-flex flex-column justify-content-center align-items-center m-3 pt-3 pb-1 pointer" (click)="changeDisplay('STATS')">
      <span class="material-icons fs-1 text-info mb-3">show_charts</span>
      <p class="fs-4 text-primary-light fw-bolder">Statistiques</p>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div class="shadow rounded d-flex flex-column justify-content-center align-items-center m-3 pt-3 pb-1 pointer" (click)="changeDisplay('CONFIGS')">
      <span class="material-icons fs-1 text-info mb-3">tune</span>
      <p class="fs-4 text-primary-light fw-bolder">Configuration</p>
    </div>
  </div>
</div>
<h4 class="text-primary-light mb-4">Depuis que nous collaborons...</h4>
<div class="d-flex flex-column flex-md-row justify-content-start align-content-center align-items-center mb-2">
  <span class="me-2 stat-info bg-info d-flex justify-content-center align-content-center align-items-center text-white fw-bolder">
    {{ stats?.total ?? 0 }}
  </span>
  <p class="text-primary mb-0">C'est le nombre de personnes de votre entreprises qui ont terminées le questionnaire</p>
</div>
<div class="d-flex flex-column flex-md-row justify-content-start align-content-center align-items-center mb-2">
  <span class="me-2 stat-info bg-info d-flex justify-content-center align-content-center align-items-center text-white fw-bolder">
    {{ stats?.victims ?? 0 }}
  </span>
  <p class="text-primary mb-0">C'est le nombre de personnes de votre entreprises identifiées en tant que potentielle victime</p>
</div>
