<div class="row mx-3 mx-md-0 mb-5 pb-5">
  <div class="col-12 col-md-4 mt-0 mt-md-5 p-0 h-100">
    <div>
      <div class="bg-primary-light p-4 shadow side-info text-center text-primary">
        <img src="{{user.company.image | image : true }}" alt="{{user.company.name}}" *ngIf="user.company && user.company.image" class="img-fluid rounded-circle logo">
        <h3 class="my-3" *ngIf="user">{{user.lastname}} {{user.firstname}}</h3>
        <h5 *ngIf="user">{{user.email}}</h5>
      </div>
      <div class="bg-light p-4 shadow side-nav fs-4 text-primary">
        <ul class="my-3 list-unstyled">
          <li class="my-3" (click)="changeDisplay('DASHBOARD')" [ngClass]="{'active': display === 'DASHBOARD'}">Tableau de bord</li>
          <li class="my-3" (click)="changeDisplay('PARAMETERS')" [ngClass]="{'active': display === 'PARAMETERS'}">Paramètre de la société</li>
          <li class="my-3" (click)="changeDisplay('STATS')" [ngClass]="{'active': display === 'STATS'}">Statistiques de la société</li>
          <li class="my-3" (click)="changeDisplay('CONFIGS')" [ngClass]="{'active': display === 'CONFIGS'}">Configurer les aides</li>
          <li class="my-2 ms-4 fs-5" (click)="changeDisplayType('EMPLOYEE')" *ngIf="display === 'CONFIGS'" [ngClass]="{'fw-bold': displayType === 'EMPLOYEE'}">Pour les salariés</li>
          <li class="my-2 ms-4 fs-5" (click)="changeDisplayType('CUSTOMER')" *ngIf="display === 'CONFIGS'" [ngClass]="{'fw-bold': displayType === 'CUSTOMER'}">Pour les clients</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-8 p-0 p-md-5">
      <app-dashboard-home *ngIf="display === 'DASHBOARD'" (changeDisplayEmitter)="changeDisplay($event)"></app-dashboard-home>
      <app-dashboard-parameters *ngIf="display === 'PARAMETERS'"></app-dashboard-parameters>
      <app-dashboard-stats *ngIf="display === 'STATS'"></app-dashboard-stats>
      <app-dashboard-configs *ngIf="display === 'CONFIGS'" [type]="displayType" [displayTypeEvent]="displayTypeEvent"></app-dashboard-configs>
  </div>
</div>
