<section class="simulator">
  <div class="simulator-content">
    <h1 class="fs-3 text-center mb-5 pb-3 text-primary">Quel est le coût humain et financier des violences conjugales dans votre entreprise ?</h1>
    <div class="row container mx-auto pb-5">
      <div class="col-12 col-lg-6">
        <div class="mb-4">
          <h2 class="fs-4 text-primary mb-3">Nombres de salariés dans l'entreprise</h2>
          <input type="number" class="form-control w-100 w-md-50 border-0 shadow" placeholder="Entrez le nombre ici" (input)="selectEmployeeCount($event)">
        </div>
        <div class="mb-4">
          <h2 class="fs-4 text-primary mb-3">Salaire moyen dans l'entreprise</h2>
          <div class="d-flex justify-content-between w-100 w-lg-75 w-xl-50">
            <button class="btn btn-white shadow rounded px-3 py-2 text-black mx-1" (click)="selectSalary(22125)" [ngClass]="{'btn-primary text-white' : salary == 22125, 'btn-white text-black': salary !== 22125}">SMIC</button>
            <button class="btn btn-white shadow rounded px-3 py-2 text-black mx-1" (click)="selectSalary(40958)" [ngClass]="{'btn-primary text-white' : salary == 40958, 'btn-white text-black': salary !== 40958}">Médian</button>
            <button class="btn btn-white shadow rounded px-3 py-2 text-black mx-1" (click)="selectSalary(51599)" [ngClass]="{'btn-primary text-white' : salary == 51599, 'btn-white text-black': salary !== 51599}">Moyen</button>
          </div>
        </div>
        <div class="mb-4">
          <h2 class="fs-4 text-primary mb-3">Répartition homme / femme</h2>
          <div class="d-flex justify-content-between w-100 w-lg-75 w-xl-50">
            <button class="btn shadow rounded px-3 py-2 mx-1" (click)="selectParity(70)" [ngClass]="{'btn-primary text-white' : parity == 70, 'btn-white text-black': parity !== 70}">70 / 30</button>
            <button class="btn shadow rounded px-3 py-2 mx-1" (click)="selectParity(50)" [ngClass]="{'btn-primary text-white' : parity == 50, 'btn-white text-black': parity !== 50}">50 / 50</button>
            <button class="btn shadow rounded px-3 py-2 mx-1" (click)="selectParity(30)" [ngClass]="{'btn-primary text-white' : parity == 30, 'btn-white text-black': parity !== 30}">30 / 70</button>
          </div>
        </div>

        <div class="mb-4">
          <h2 class="fs-4 text-primary mb-3">À combien évaluez-vous le temps de travail perdu pour une victime de violences conjugales ?</h2>
          <div class="d-flex justify-content-between w-100 w-lg-75 w-xl-50">
            <button class="btn shadow rounded px-3 py-2 mx-1" (click)="selectLostTime(0.2)" [ngClass]="{'btn-primary text-white' : lostTime == 0.2, 'btn-white text-black': lostTime !== 0.2}">20%</button>
            <button class="btn shadow rounded px-3 py-2 mx-1" (click)="selectLostTime(0.35)" [ngClass]="{'btn-primary text-white' : lostTime == 0.35, 'btn-white text-black': lostTime !== 0.35}">35%</button>
            <button class="btn shadow rounded px-3 py-2 mx-1" (click)="selectLostTime(0.5)" [ngClass]="{'btn-primary text-white' : lostTime == 0.5, 'btn-white text-black': lostTime !== 0.5}">50%</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
        <div class="bg-light rounded shadow text-primary px-5 py-3 mt-5 mt-lg-0">
          <h2 class="text-primary-light mb-4" *ngIf="allParametersFilled()">Selon les valeurs que vous avez renseignées :</h2>
          <h2 class="text-primary-light mb-4" *ngIf="!allParametersFilled()">Renseignez tous les paramètres pour connaître ces données :</h2>
          <p>Au sein de votre effectif salarié, il y a statistiquement :</p>
          <ul>
            <li><span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">X</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ victimCount }}</span> victime<span *ngIf="victimCount > 1">s</span></li>
            <li><span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">X</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ abuserCount }}</span> agresseur<span *ngIf="abuserCount > 1">s</span></li>
            <li><span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">X</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ childCount }}</span> enfant<span *ngIf="childCount > 1">s</span> victime<span *ngIf="childCount > 1">s</span></li>
          </ul>
          <p class="mb-3 text-wrap-pretty">Selon les statistiques officielles, les violences conjugales causeraient une perte de <span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">XXX €</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ formatNumber(loss) }}€</span> par an au sein de votre entreprise.</p>
          <p class="mb-3 text-wrap-pretty">Les violences subies par vos collaboratrices et collaborateurs représentent un coût de <span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">XXX €</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ formatNumber(publicLoss) }}€</span> pour les finances publiques</p>
          <p class="fw-bold">Utiliser <span class="text-primary-light fw-bolder">Opale.care</span> vous permettra statistiquement d'avoir un impact positif direct sur la vie de <span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">XXX</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ concernedCount }}</span> personnes.
            Compte tenu de vos effectifs, en utilisant Opale.care :</p>
          <ul>
            <li>Votre entreprise économisera <span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">XXX €</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ formatNumber(economy) }}€</span></li>
            <li>Vous permettrez de réduire les dépenses publiques de <span class="text-primary-light fw-bolder" *ngIf="!allParametersFilled()">XXX €</span><span class="text-primary-light fw-bolder" *ngIf="allParametersFilled()">{{ formatNumber(publicEconomy) }}€</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-md-50 mx-auto text-center">
      <p class="text-start text-primary">Vous souhaitez découvrir Opale.care et sa solution d'accompagnement pour les entreprises ? Avoir des explications sur les chiffres évalués ?
        Merci de remplir le <a class="text-primary-light fw-bolder text-decoration-none" href="#contact">formulaire de contact</a>,  nous reviendrons vers vous dans les plus brefs délais.</p>
      <a href="https://calendly.com/vigdis-opale" target="_blank" class="btn btn-info text-white rounded btn-block fw-bold mx-auto text-center mb-5">J'agis dès maintenant</a>
      <p class="text-muted fs-mini fst-italic">Chiffres tirés de l'<a href="https://shs.cairn.info/revue-sante-publique-2010-4-page-405?lang=fr" target="_blank" class="text-primary-light">Evaluation économique des violences conjugales en France</a>, publiée en 2006 par Marc Nectoux, Claude Mugnier, Sandrine Baffert, Maité Albagly et Bertrand Thélot</p>
    </div>
  </div>
</section>
<section class="my-5">
  <app-blog></app-blog>
</section>
