<div class="modal-header d-flex justify-content-between">
  <h4 class="modal-title">Modifier le référent</h4>
  <button type="button" class="btn ds-3" aria-label="Close" (click)="cancel()">
    <span class="fs-1 text-info" aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="mb-3">
    <label>Texte à afficher pour savoir qui contacter</label>
    <textarea [(ngModel)]="updatedReferent.referent" type="text" class="form-control"></textarea>
  </div>
  <div class="mb-3">
    <label>Email :</label>
    <input [(ngModel)]="updatedReferent.email" type="text" class="form-control">
  </div>
  <div class="mb-3">
    <label>Téléphone :</label>
    <input [(ngModel)]="updatedReferent.phone" type="text" class="form-control">
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" (click)="cancel()">Annuler</button>
  <button class="btn btn-primary" (click)="submit()">Valider</button>
</div>
