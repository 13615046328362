import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CompanyService} from "../../../services/company/company.service";
import {fakeAsync} from "@angular/core/testing";
import {AlertService} from "../../../services/alert/alert.service";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-dashboard-parameters',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './dashboard-parameters.component.html',
  styleUrl: './dashboard-parameters.component.scss'
})
export class DashboardParametersComponent implements OnInit{

  form: FormGroup;
  loading = true;

  code: string = '';

  constructor(
    private companySrv: CompanyService,
    private alertSrv: AlertService
  ) {
    this.form = new FormGroup({
      name: new FormControl('...', [Validators.required]),
      website: new FormControl('...', null),
      description: new FormControl('...', null),
      summary: new FormControl('...', null)
    });
  }

  ngOnInit(): void {
    this.companySrv.details().subscribe(
      (data: any) => {
        this.form.patchValue({
          name: data.name,
          website: data.website,
          description: data.description,
          summary: data.summary
        });
        this.code = data.code;
        this.loading = false;
      },
      (error) => {
        console.error('Data recovery error', error);
      }
    );
  }

  onSubmit() {
    this.loading = true;
    this.companySrv.edit(this.form.value).subscribe(
      data => this.success(data),
      (error) => {},
      () => {
        this.loading = false;
      }
    );
  }

  success(data: any) {
    this.form.patchValue({
      name: data.name,
      website: data.website,
      description: data.description,
      summary: data.summary
    });
    this.alertSrv.success('Données enregistrées avec succès!');
  }
}
