import {Component, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import {BlogComponent} from "../../components/blog/blog.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    BlogComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

  pricingTable = [
    { minEmployees: 0, maxEmployees: 150, price: 1000 },
    { minEmployees: 151, maxEmployees: 500, price: 5000 },
    { minEmployees: 501, maxEmployees: 1000, price: 10000 },
  ];

  employeeCount: number = 0;
  salary: number | null = null;
  parity: number | null = null;
  lostTime: number | null = null;

  victimCount: number = 0;
  abuserCount: number = 0;
  childCount: number = 0;
  concernedCount: number = 0;
  loss: number = 0;
  publicLoss: number = 0;
  economy: number = 0;
  publicEconomy: number = 0;

  constructor(
    private authSrv: AuthService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    if (this.authSrv.isLogged()) {
      await this.router.navigate(['/dashboard']);
    }
  }

  selectEmployeeCount(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.employeeCount = Number(input.value);
    this.updateValues();
  }

  selectSalary(salary: number): void {
    this.salary = salary;
    this.updateValues();
  }

  selectParity(parity: number): void {
    this.parity = parity;
    this.updateValues();
  }

  selectLostTime(lostTime: number): void {
    this.lostTime = lostTime;
    this.updateValues();
  }

  allParametersFilled(): boolean {
    if (this.parity && this.employeeCount && this.salary && this.lostTime) {
      return true;
    }

    return false;
  }

  updateValues(): void {
    if (this.employeeCount && this.employeeCount >= 1 && this.parity && this.salary && this.lostTime) {
      const maleCount = (this.employeeCount * this.parity) / 100;
      const femaleCount = this.employeeCount - maleCount;

      const maleVictims = maleCount / 18;
      const femaleVictims = femaleCount / 6;

      this.victimCount = Math.round(maleVictims + femaleVictims);
      this.childCount = Math.round(this.victimCount * 1.64);

      const maleAbusers = maleCount / 18;
      const femaleAbusers = maleCount / 54;

      this.abuserCount = Math.round(maleAbusers + femaleAbusers);

      this.concernedCount = this.abuserCount + this.victimCount + this.childCount;

      this.loss = Math.round(this.victimCount * this.salary * this.lostTime);

      this.publicLoss = this.victimCount * 68000 * 0.56;

      this.economy = this.getPriceForEmployees(this.employeeCount) * 87 * 0.44;
      this.publicEconomy = this.getPriceForEmployees(this.employeeCount) * 87 * 0.56;
    }
  }

  getPriceForEmployees(employeeCount: number): number {
    const pricingTier = this.pricingTable.find(
      (tier) =>
        employeeCount >= tier.minEmployees && employeeCount <= tier.maxEmployees
    );

    return pricingTier ? pricingTier.price : 0;
  }

  formatNumber(number: number): string {
    return number.toLocaleString('fr-FR');
  }
}
