import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'image',
  standalone: true
})
export class ImagePipe implements PipeTransform {

  transform(filename: string, upload: boolean): any {
    if (upload)
    {
      return `${environment.api.endpoint}assets/img/uploads/${filename}`;
    }
    return `${environment.api.endpoint}assets/img/${filename}`;
  }

}
