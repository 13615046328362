import {Component, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-modal-referent',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './modal-referent.component.html',
  styleUrl: './modal-referent.component.scss'
})
export class ModalReferentComponent implements OnInit{

  @Input() action: any;

  updatedReferent: any;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.updatedReferent = {
      referent: this.action.referent,
      email: this.action.email,
      phone: this.action.phone
    };
  }

  submit() {
    this.bsModalRef.content.referent = this.updatedReferent;
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
