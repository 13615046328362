import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ToolsService} from "../tools/tools.service";
import {Router} from "@angular/router";
import {UserService} from "../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  signInEvent = new Subject<any>();

  constructor(
    private http: HttpClient,
    private toolsSrv: ToolsService,
    private router: Router,
    private userSrv: UserService
  ) { }

  login(credentials: any): Observable<any> {
    return this.http.post<any>(`${this.toolsSrv.getApiEndpoint()}auth/signin`, credentials);
  }

  saveToken(token: string): void {
    sessionStorage.setItem('token', token);
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  async removeAuth(): Promise<void> {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');

    await this.router.navigate(['/']);

    this.signInEvent.next(false);
  }

  isLogged(): boolean {
    const token = sessionStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  }

  async storeUser(): Promise<void> {
    const userObservable = this.userSrv.me();
    const user = await userObservable.toPromise();
    sessionStorage.setItem('user', JSON.stringify(user));
    this.signInEvent.next(true);
  }

  getUser(): any {
    const user = sessionStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }
}
