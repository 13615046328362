import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {AuthService} from "../../services/auth/auth.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy{

  url: string;
  isLogged: boolean = false;

  loginSubscription!: Subscription;

  constructor(
    public authSrv: AuthService
  ) {
    this.url = environment.wwwEndpoint;
  }

  async ngOnInit(): Promise<void> {
    this.setLogin();
    this.loginSubscription = this.authSrv.signInEvent.subscribe( (status: boolean) => {
      this.setLogin();
    });
  }

  setLogin() {
    this.isLogged = this.authSrv.isLogged();
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
  }
}
