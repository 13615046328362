<form [formGroup]="form" (submit)="onSubmit()">
  <div class="row text-primary fw-bolder mt-5 mt-md-0" [ngClass]="{'loading' : loading}">
    <div class="col-12 col-md-6 mb-4">
      <label for="name" class="mb-2">Nom de la société</label>
      <input type="text" placeholder="Nom" id="name" class="form-control border-0 shadow"  formControlName="name">
    </div>
    <div class="col-12 col-md-6 mb-4">
      <label for="website" class="mb-2">Site internet</label>
      <input type="text" placeholder="website.fr" id="website" class="form-control border-0 shadow"  formControlName="website">
    </div>
    <div class="col-12 col-md-6 mb-4">
      <label for="description" class="mb-2">Description de l'entreprise</label>
      <textarea id="description" class="form-control border-0 shadow"  formControlName="description"></textarea>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <label for="summary" class="mb-2">Résumé de l'entreprise affiché dans le catalogue</label>
      <textarea id="summary" class="form-control border-0 shadow"  formControlName="summary"></textarea>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <label for="code" class="mb-2">Code entreprise</label>
      <input type="text" placeholder="website.fr" id="code" class="form-control border-0 shadow" value="{{code}}" disabled>
    </div>
  </div>
  <button class="btn btn-info btn-lg text-white fw-bolder mt-4" [disabled]="loading">Valider</button>
</form>
