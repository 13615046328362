export const environment = {
  production: false,
  envName: 'dev',
  wwwEndpoint: 'https://pro.dev.opale.care/',
  blogEndpoint: 'https://dev.opale.care/fr/blog/',
  api: {
    publicKey: 'bc14f3a5-e0c8-40de-8e82-f2b08c07fdfd',
    endpoint: 'https://api.pro.dev.opale.care/',
  }
}
