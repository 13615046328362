import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {DashboardHomeComponent} from "../../components/dashboard/dashboard-home/dashboard-home.component";
import {
  DashboardParametersComponent
} from "../../components/dashboard/dashboard-parameters/dashboard-parameters.component";
import {DashboardStatsComponent} from "../../components/dashboard/dashboard-stats/dashboard-stats.component";
import {DashboardConfigsComponent} from "../../components/dashboard/dashboard-configs/dashboard-configs.component";
import {AuthService} from "../../services/auth/auth.service";
import {Subject, Subscription} from "rxjs";
import {ImagePipe} from "../../pipes/image.pipe";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    DashboardHomeComponent,
    DashboardParametersComponent,
    DashboardStatsComponent,
    DashboardConfigsComponent,
    ImagePipe
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  display: string = 'DASHBOARD';
  displayType: string = 'EMPLOYEE';

  displayTypeEvent: Subject<any> = new Subject();

  user: any;
  loginSubscription!: Subscription;

  constructor(
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.setUser();
    this.loginSubscription = this.authSrv.signInEvent.subscribe( (status: boolean) => {
      this.setUser();
    });
  }

  setUser(): void {
    this.user = this.authSrv.getUser();
  }

  changeDisplay(display: string): void {
    this.display = display;
  }

  changeDisplayType(displayType: string): void {
    this.displayType = displayType;
    this.displayTypeEvent.next(displayType);
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
    this.displayTypeEvent.complete();
  }
}
