import {Component, OnInit} from '@angular/core';
import {BlogService} from "../../services/blog/blog.service";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {ImagePipe} from "../../pipes/image.pipe";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [
    NgForOf,
    ImagePipe,
    DatePipe,
    NgIf
  ],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent implements OnInit{

  articles: Array<any> = [];
  url: string;

  constructor(
    private blogSrv: BlogService
  ) {
    this.url = environment.blogEndpoint;
  }

  async ngOnInit(): Promise<void> {
    this.blogSrv.articles().subscribe(
      (data: any) => {
        this.articles = data;
      },
      (error) => {
        console.error('Data recovery error', error);
      }
    );
  }
}
