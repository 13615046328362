import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import {SurveyService} from "../../../services/survey/survey.service";

interface Stats {
  total: number;
  victims: number;
}

@Component({
  selector: 'app-dashboard-home',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './dashboard-home.component.html',
  styleUrl: './dashboard-home.component.scss'
})
export class DashboardHomeComponent implements OnInit {

  @Output() changeDisplayEmitter = new EventEmitter<string>();

  stats: Stats | null = null;

  constructor(
    private surveySrv: SurveyService
  ) {
  }

  ngOnInit(): void {
    this.surveySrv.dashboard().subscribe(
      (data: Stats) => {
        this.stats = data;
      },
      (error) => {
        console.error('Data recovery error', error);
        this.stats = { total: 0, victims: 0 };
      }
    );
  }

  changeDisplay(display: string): void {
    this.changeDisplayEmitter.emit(display);
  }
}
