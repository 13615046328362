import {Component, OnInit} from '@angular/core';
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {StatsService} from "../../../services/stats/stats.service";
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-dashboard-stats',
  standalone: true,
  imports: [
    NgClass,
    ProgressbarModule,
    NgIf,
    NgForOf
  ],
  templateUrl: './dashboard-stats.component.html',
  styleUrl: './dashboard-stats.component.scss'
})
export class DashboardStatsComponent implements OnInit{

  stats: any;
  loading = true;

  constructor(
    private statsSrv: StatsService
  ) {
  }

  ngOnInit(): void {
    this.statsSrv.getStats().subscribe(
      (data: any) => {
        this.stats = data;
        this.loading = false;
      },
      (error) => {
        console.error('Data recovery error', error);
      }
    );
  }
}
