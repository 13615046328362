<h2 class="fw-bolder mb-5 text-center text-primary" *ngIf="articles">Nos derniers articles</h2>
<div class="row container justify-content-center mx-auto">
  <ng-container *ngFor="let article of articles">
    <a class="text-decoration-none col-12 col-lg-4 py-4 py-lg-0 text-black" href="{{ url + article.slug }}">
      <div class="bg-white rounded m-2 m-md-1 m-lg-2 shadow mt-3">
        <div class="img-fluid h-100 w-100">
          <img src="{{ article.image | image : true }}" alt="{{article.title}}" class="h-100 w-100 rounded-top">
        </div>
        <div class="p-1 p-md-3 position-relative article-content text-start">
          <span class="my-2 p-2 badge bg-primary">{{ article.category }}</span>
          <p class="text-muted fs-7 mb-3">Temps de lecture : {{ article.readingTime}} minutes</p>
          <h3 class="fs-5 my-2 text-primary text-center">{{ article.title }}</h3>
          <p class="text-break">{{ article.summary }}</p>
          <div class="d-flex">
            <img src="{{ 'vigdis.png' | image : false }}" alt="{{ article.author }}" class="img-fluid img-author rounded-circle shadow">
            <div class="d-flex flex-column justify-content-end align-items-start ms-2">
              <p class="m-0 text-muted">{{ article.author }}</p>
              <p class="text-muted m-0">{{article.publishedAt | date: 'longDate' }}</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </ng-container>
</div>
