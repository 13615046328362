<h2 class="fs-4 text-primary-light mb-4 mt-5 mt-md-0" *ngIf="type === 'EMPLOYEE'">Aides à proposer pour les salariés</h2>
<h2 class="fs-4 text-primary-light mb-4 mt-5 mt-md-0" *ngIf="type === 'CUSTOMER'">Aides à proposer pour les clients</h2>
<div class="mb-4">
  <ng-container *ngFor="let action of actions; let isLast = last">

    <div class="d-flex justify-content-start align-items-center mb-3">
    <div style="width: 40px; height: 40px" class="non-select bg-primary d-flex justify-content-center align-items-center text-white rounded fs-4 me-3 shadow pointer" *ngIf="action.isImplemented" (click)="changeIsImplemented(action, false )">X</div>
    <div style="width: 40px; height: 40px" class="non-select d-flex justify-content-center align-items-center rounded fs-4 me-3 shadow pointer" *ngIf="!action.isImplemented" (click)="changeIsImplemented(action, true)"></div>
    <div class="d-flex flex-column">
      <span [innerHTML]="transformContent(action)"></span>
      <span (click)="editReferent(action)" *ngIf="action.isImplemented && (action.referent || action.email || action.phone)" class="mt-1 bg-info p-2 rounded text-white pointer" style="width: fit-content"><ng-container *ngIf="action.email">mail: {{action.email}}</ng-container> <ng-container *ngIf="action.phone">phone: {{action.phone}}</ng-container></span>
      <span (click)="editReferent(action)" *ngIf="action.isImplemented && (!action.referent && !action.email && !action.phone)" class="mt-1 bg-info p-2 rounded text-white fs-6 pointer" style="width: fit-content">Ajouter un référent</span>
    </div>
    </div>
    <hr class="text-primary-light" *ngIf="!isLast">
  </ng-container>
</div>
<button class="btn btn-info text-white" (click)="update()" [disabled]="loading">Valider</button>
