import { Component } from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  form: FormGroup;
  loading = false;

  constructor(
    private authSrv: AuthService,
    private router: Router
  ) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  async onSubmit() {
    this.loading = true;
    this.authSrv.login(this.form.value).subscribe(
      data => this.success(data),
      (error) => {},
      () => {
        this.loading = false;
      }
    );
  }

  async success(data: any) {
    this.authSrv.saveToken(data.token);
    await this.authSrv.storeUser();

    await this.router.navigate(['/dashboard']);
  }
}
