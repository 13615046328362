import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToolsService} from "../tools/tools.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(
    private http: HttpClient,
    private toolsSrv: ToolsService
  ) { }

  employee(): Observable<any> {
    return this.http.get(`${this.toolsSrv.getApiEndpoint()}action/employee`);
  }

  customer(): Observable<any> {
    return this.http.get(`${this.toolsSrv.getApiEndpoint()}action/customer`);
  }

  update(params: any): Observable<any> {
    return this.http.post<any>(`${this.toolsSrv.getApiEndpoint()}action/update`, params);
  }
}
